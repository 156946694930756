
import { Component, Vue } from "vue-property-decorator";
import ProjectViewQuota from "@/components/board/ProjectViewQuota.vue";
import ProjectViewCheckList from "@/components/board/ProjectViewCheckList.vue";
import ProjectViewSkeleton from "@/components/board/ProjectViewSkeleton.vue";
import moment from "moment";
import QuotaParticipation from "@/components/board/QuotaParticipation.vue";
import Sampling from "@/components/board/Sampling.vue";
import { QUESTION } from "@/types/question";
import MakeTemplateModal from "@/components/modal/MakeTemplateModal.vue";
import { IProjectConfig, IQuestionValue } from "@/interface/admin/question";

@Component({
  computed: {
    LINK_STATUS() {
      return QUESTION.LINK_STATUS;
    },
  },
  components: {
    MakeTemplateModal,
    ProjectViewQuota,
    ProjectViewCheckList,
    ProjectViewSkeleton,
    QuotaParticipation,
    Sampling,
  },
})
export default class ProjectView extends Vue {
  data: Array<IQuestionValue> = [];
  config: any | null = null;

  refunded = false;
  loading = false;
  isPaid = false;
  isTypeP = true;
  title = "";
  snum = "";
  owner = "";
  quota: any = {};
  qData: any = [];
  linkStatus = 0;
  regDate = "";
  statusOptions = [
    { value: QUESTION.LINK_STATUS.DEFAULT, text: "링크생성전", disabled: true },
    {
      value: QUESTION.LINK_STATUS.TEST,
      text: "테스트 링크 생성",
      disabled: true,
    },
    { value: QUESTION.LINK_STATUS.REAL, text: "링크 확정" },
    /*{ value: QUESTION.LINK_STATUS.PAID , text: '결제완료', disabled: true },*/
    { value: QUESTION.LINK_STATUS.REVIEW_HOLD, text: "검수 대기" },
    { value: QUESTION.LINK_STATUS.REVIEW, text: "검수중" },
    { value: QUESTION.LINK_STATUS.REVIEW_DONE, text: "검수 완료" },
    { value: QUESTION.LINK_STATUS.REJECT, text: "반려" },
    { value: QUESTION.LINK_STATUS.REFUND, text: "환불", disabled: true },
    { value: QUESTION.LINK_STATUS.FW_START, text: "진행중" },
    { value: QUESTION.LINK_STATUS.FW_END, text: "진행완료" },
    { value: QUESTION.LINK_STATUS.FW_PAUSE, text: "중단" },
    { value: QUESTION.LINK_STATUS.FW_CANCEL, text: "취소" },
    { value: QUESTION.LINK_STATUS.FW_SHUT_DOWN, text: "종료" },
    { value: QUESTION.LINK_STATUS.SAMPLE, text: "샘플", disabled: true },
  ];
  token = this.$store.getters.token;
  form: { name: string; reason: string } = { name: "", reason: "" };
  preview = false;
  previewLoading = false;
  resultShow = false;

  limitCount = 0;
  private surveyParticipations = {
    ALL: { NAME: "ALL", COUNT: 0 },
    BAD: { NAME: "BAD", COUNT: 0 },
    COMPLETE: { NAME: "COMPLETE", COUNT: 0 },
    DROP: { NAME: "DROP", COUNT: 0 },
    OVER: { NAME: "OVER", COUNT: 0 },
    OUT: { NAME: "OUT", COUNT: 0 },
  };

  created() {
    this.load();
  }

  openRegistrationModal() {
    this.$bvModal.show("new-template-registration-modal");
  }

  get getProjectOwner() {
    return this.owner.split(",")[0];
  }

  get isProd() {
    return process.env.NODE_ENV === "production";
  }

  get host() {
    return this.isProd
      ? process.env.VUE_APP_HOST_FRONT
      : `http://localhost:8080`;
  }

  numberComma(number) {
    return new Intl.NumberFormat().format(number);
  }

  async load() {
    this.loading = true;
    const { id: snum } = this.$route.params;
    const { data } = await this.axios.get<{
      TITLE: string;
      CONFIG: string;
      ROLE: any;
      regDate: any;
      _id: any;
      DATA: any;
      question: any;
      quota: any;
    }>(`/admin/project/view/${snum}`);
    const { question, quota: projectQuota } = data;
    const { TITLE, CONFIG, ROLE, regDate, _id, DATA, SNUM } = question;
    this.data = DATA;
    this.title = TITLE;
    this.owner = ROLE.OWNER.join(", ");
    this.snum = SNUM.toString();
    this.config = CONFIG;
    this.quota = CONFIG.SIMPLE_SURVEY_QUOTA;
    this.linkStatus = CONFIG.LINK_STATUS;
    this.isPaid = CONFIG.PAYMENT_INFO ? true : false;
    this.refunded = CONFIG.LINK_STATUS === 4;
    this.isTypeP = CONFIG.SIMPLE_SURVEY_TYPE === 2;
    this.regDate = this.dateFormat(new Date(regDate));

    this.qData = DATA.filter((question) => {
      const { NAME } = question;
      return QUESTION.HIDE_QUESTIONS.indexOf(NAME) === -1;
    });

    this.loading = false;

    this.limitCount = 0;
    const quotaArray = DATA.filter(
      (item) => item.TYPE === QUESTION.QUESTION_TYPES.QUOTA
    );
    quotaArray.forEach((data) => {
      const { QUOTA } = data;
      let sum = 0;
      for (const row in QUOTA) {
        sum += Number(QUOTA[row]["quota"]);
      }
      this.limitCount += sum;
    });

    this.surveyParticipations.ALL.COUNT = projectQuota.all
      ? projectQuota.all
      : 0;
    this.surveyParticipations.COMPLETE.COUNT = projectQuota.complete
      ? projectQuota.complete
      : 0;
    this.surveyParticipations.DROP.COUNT = projectQuota.drop
      ? projectQuota.drop
      : 0;
    this.surveyParticipations.OVER.COUNT = projectQuota.over
      ? projectQuota.over
      : 0;
    this.surveyParticipations.OUT.COUNT = projectQuota.out
      ? projectQuota.out
      : 0;
  }

  showReceipt() {
    if (this.config && this.config.PAYMENT_INFO) {
      const url = this.config.PAYMENT_INFO.data.receipt_url;
      window.open(
        url,
        "",
        "height=955, width= 700, top=150, left=150, toolbar=no, resizeable=no"
      );
    }
  }

  async cancelReceipt() {
    const data = this.config.PAYMENT_INFO.data;
    const sendData = {
      snum: this.snum,
      receipt_id: data.receipt_id,
      price: data.price,
      tax_free: data.taxFree,
      name: this.form.name,
      reason: this.form.reason,
      userId: this.$store.state.userId,
    };
    const result = await this.axios.post(
      "/admin/project/paymentCancel",
      sendData
    );
    if (result) {
      this.$toast.success("결제 취소 완료");
      this.$bvModal.hide("modal-1");
    }
    await this.load();
  }

  dateFormat(date: Date): string {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  async statusChange() {
    if(this.linkStatus === 999 ) return;
    const sendData = {
      _id: this.snum,
      status: this.linkStatus,
    };
    const data = await this.axios.patch(
      `/admin/project/${this.snum}`,
      sendData
    );
    if (data.status === 200) {
      let STATUS = "";
      switch (this.linkStatus) {
        case 1:
          STATUS = "링크확정";
          break;
        case 2:
          STATUS = "결제완료";
          break;
        case 201:
          STATUS = "검수대기";
          break;
        case 202:
          STATUS = "검수중";
          break;
        case 203:
          STATUS = "검수완료";
          break;
        case 3:
          STATUS = "반려";
          break;
        case 101:
          STATUS = "진행중";
          break;
        case 102:
          STATUS = "진행완료";
          break;
        case 103:
          STATUS = "중단";
          break;
        case 104:
          STATUS = "취소";
          break;
        case 105:
          STATUS = "강제종료";
          break;
        case 999:
          STATUS = "샘플";
          break;
        default:
          break;
      }
      await this.unisurveyAlertUpdate();
      this.$toast.success(
        `SNUM: ${this.snum} 의 상태가 ${STATUS} (으)로 변경되었습니다.`
      );
    }
  }

  /**
   * @description: 설문 미리보기
   * 검수 대기 설문을 클랙했을 때, 해당 설문은 검수 중으로 상태 변경
   * @private
   */
  private async showSurveyPreview() {
    this.preview = true;
    this.previewLoading = true;
    try {
      if (this.linkStatus === 201) {
        await this.axios.put(`/admin/project/status/inspection/${this.snum}`);
        await this.unisurveyAlertUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async unisurveyAlertUpdate() {
    await this.$store.dispatch("socketStore/unisurveyAlertUpdate", {
      SNUM: this.snum,
      userId: this.getProjectOwner,
      ALERT_CHECK: false,
    });
    await this.$store.dispatch("socketStore/emitAdminHeaderInfo");
  }

  private async hideSurveyPreview() {
    this.preview = false;
    await this.load();
    await this.unisurveyAlertUpdate();
  }

  private resultFunc() {
    this.resultShow = !this.resultShow;
  }
}
