
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {QUESTION} from "@/types/question";

type List = {
  SNUM: number,
  TITLE: string,
}

interface ProjectList {
  _id: number,
  LIST: List[]
}

@Component
export default class AlertMessage extends Vue {
  @Prop({default: []}) projectList: ProjectList[];
  @Prop({default: false}) active: boolean;

  messageBoxActive = false;

  @Watch('active')
  messageBoxWatch() {
    this.messageBoxActive = this.active;
  }

  @Emit('messageBoxActive')
  closeBox() {
    return !this.active;
  }

  redirectProject(SNUM: number) {
    this.closeBox();
    this.$router.push(`/admin/list/${SNUM}`);
  }

  makeKeywordMessage(STATUS: number) {
    switch (STATUS) {
      case QUESTION.LINK_STATUS.REJECT:
        return '반려된'
      case QUESTION.LINK_STATUS.REVIEW_HOLD:
        return '검수 대기중인'
      default:
        return ''
    }
  }
}
