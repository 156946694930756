
import { Component, Vue } from 'vue-property-decorator';
import QuestionReplyComponent from '@/components/question/QuestionReply.vue'
import * as BOARD from '@/types/board';

@Component({
  components: {
    QuestionReplyComponent,
  },
})
export default class UserQuestionReply extends Vue {
  listId = '';
  editLevel = -1;
  BOARD = BOARD;
  loading = true;
  boardQuestion:any = BOARD.boardQuestionInit();
  boardQuestionList:any = [];
  boardQuestionLast:any = BOARD.boardQuestionInit();

  created() {
    const { listId, level } = this.$route.params as { listId: string, level: string };
    this.editLevel = Number(level);
    this.listId = listId;
  }

  toList() {
    return this.$router.push({
      path: `/admin/user/${this.boardQuestion.USER_ID}`,
    })
  }

  toPrev() {
    return this.$router.push({
      path: `/admin/user/question/${this.listId}`,
    })
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/QuestionList/Read/${this.listId}`);
      const { question } = data;
      this.boardQuestion = question[0];
      this.boardQuestionList = question;
      this.boardQuestionLast = question[question.length-1];
      if(this.editLevel >= 0){
        this.boardQuestion = question[this.editLevel];
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }
}
