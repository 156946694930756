
import { Component, Vue } from "vue-property-decorator";
import {IUserListInfo} from "@/interface/user/user";
import { Dictionary } from 'vue-router/types/router';
import SearchIcon from "@/components/icons/common/SearchIcon.vue";
import moment from "moment";

interface OWNER {
  OWNER: string[]
}

interface IProjectList {
  ROLE: OWNER,
  CONFIG: any
  SNUM: number,
  TITLE: string,
  isUse: boolean,
  regDate: Date,
  _id: string,
  STATUS: string
}

@Component({
  components: {
    SearchIcon,
  }
})
export default class MonthlyAccountList extends Vue{
  page = 1;
  totalRow = 0;
  numberOfPages = 1;
  limitPage = 10;
  search = '';
  searchType = 'NAME';
  searchDateStart = '';
  searchDateEnd = '';
  loading = false;
  searchButtonHover = false;
  accountList: IProjectList[] = [];

  redirectUserDetail(userId: string) {
    this.$router.push({ path: `/admin/user/${userId}`, query: this.$route.query })
  }

  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  async created(){
    this.init();
    await this.load();
  }

  init() {
    const { page, search, searchType, limitPage, searchDateStart, searchDateEnd  } = this.$route.query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
    if (limitPage) this.limitPage = +limitPage;
    if (searchDateStart) this.searchDateStart = searchDateStart;
    if (searchDateEnd) this.searchDateEnd = searchDateEnd;
  }

  async load() {
    this.loading = true;
    try {
      const { data } = await this.axios.get('/admin/project/account/list', {
        params: {
          pageNum: this.page,
          searchType: this.searchType,
          search: this.search,
          perPage: this.limitPage,
          searchDateStart: this.searchDateStart,
          searchDateEnd: this.searchDateEnd,
        }
      });
      const { result, list, numberOfPages } = data;
      if (result) {
        this.accountList = list.map((v)=> {
          if(v.CONFIG.PAYMENT_INFO) {
            v.CONFIG.PAYMENT_INFO.data.purchased_at = moment(v.CONFIG.PAYMENT_INFO.data.purchased_at).format('YYYY-MM-DD');
            v.CONFIG.PAYMENT_INFO.data.supply_price = Math.round(v.CONFIG.PAYMENT_INFO.data.price/1.1);
            v.CONFIG.PAYMENT_INFO.data.charge = Math.round(v.CONFIG.PAYMENT_INFO.data.supply_price*0.15);
          }
          return v;
        });
        this.numberOfPages = numberOfPages;
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  numberComma(number){
    return new Intl.NumberFormat().format(number);
  }

  searchList() {
    const query = this.makeQuery({
      page: 1,
      searchDateStart: this.searchDateStart,
      searchDateEnd: this.searchDateEnd
    });
    this.$router.push(query).catch(() => ({}));
  }

  async xlsxDownload(){
    this.loading = true;
    try{
      const paramData = {
        pageNum: this.page,
        searchType: this.searchType,
        search: this.search,
        perPage: this.limitPage,
        searchDateStart: this.searchDateStart,
        searchDateEnd: this.searchDateEnd,
      }
      const data = await this.axios({
        url: '/admin/project/account/xlsx',
        method: 'GET',
        params: paramData,
        responseType: 'blob',
      }).then((response)=>{
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', '정산리스트.xlsx')
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
      })
    }catch (e) {
      console.log(e)
    }finally {
      this.loading = false;
    }
  }

}
