
import { Component, Vue } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import ImageUpload from '@/components/question/ImageUpload.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import {Blog, BLOG_CATEGORY_OPTION, blogInit} from "@/types/blog";

@Component({
  components: {
    VueEditor,
    ImageUpload,
  },
})
export default class AdminBlogWrite extends Vue {
  loading = false;
  blog: Blog = blogInit();

  dropOption= {
    acceptedFiles: ".mp4,.wmv,.jpg,.jpeg,.png,.bmp",
    method: "post",
    autoProcessQueue: false,
  }

  customToolbar: any[] = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" }
    ],
    [{ color: [] }],
    ["link", "image", "video", "formula", 'code-block'],
  ];
  dataUrlList: string[] = [];
  uploadImages: File[] = [];
  thumbnailImage: File[] = [];
  thumbName='';
  thumbnailUrl = '';
  path = location.origin + '/uploads/Blog/';
  file = [];
  fileName = '';

  BLOG_CATEGORY_OPTION = BLOG_CATEGORY_OPTION;

  $refs: Vue['$refs'] & {
    formFile: HTMLFormElement;
  };

  async drop(e){
    e.preventDefault();
    const file = e.dataTransfer.files;
    const isImage = this.isImage(file[0]);
    if (isImage) {
      const dataUrl = await this.getDataUrl(file[0]);
      const sendData = {
        file: file[0],
        dataUrl,
      };
      this.imageAppend(sendData);
    }
  }

  async save(): Promise<void> {
    try {
      const { TITLE, CONTENTS, CATEGORY } = this.blog;
      if (TITLE.length < 2) {
        this.$common.makeToast(ToastMessage.EMPTY_TITLE, ToastVariant.DANGER, this.$bvToast);
        return;
      } else if (CONTENTS.length < 5) {
        this.$common.makeToast(ToastMessage.EMPTY_CONTENT, ToastVariant.DANGER, this.$bvToast);
        return;
      }

      const isFiles = this.dataUrlList.length;
      const replacerContent = isFiles ? this.getReplacer(CONTENTS) : CONTENTS;
      const formData: FormData = new FormData();

      this.uploadImages.forEach((file, fileIdx) => {
        formData.append('fileList', file);
      });
      const sendContent = replacerContent.split('</iframe>').length>0? this.getIframeSize(replacerContent): replacerContent;
      const sendData = {
        title: TITLE,
        category: CATEGORY,
        contents: sendContent,
      };

      const { data } = await this.axios.post(`/admin/board/blog/write`, sendData);

      if (this.uploadImages.length > 0) {
        const { _id } = data;
        await this.uploadFile(_id, formData);
      }
      if (this.thumbnailImage.length>0) {
        const { _id } = data;
        const thumbnailFormData: FormData = new FormData();
        this.thumbnailImage.forEach((thumb, index)=>{
          thumbnailFormData.append('fileList', thumb);
        });
        await this.uploadFile(_id, thumbnailFormData, true);
      }

      const { result } = data;

      if (result) {
        this.$common.makeToast(ToastMessage.WRITE_BLOG, ToastVariant.SUCCESS, this.$bvToast);
        await this.$router.push('/admin/blog');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadFile(_id: string, formData: FormData, isThumb?: boolean) {
    const postUrl = `/admin/board/blog/write/${isThumb? 'thumbnail/':''}${_id}`;
    await this.axios.post(postUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  private imgResizeEvent(data){
    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    img.src = data.dataUrl;
  }

  private thumbnailAppend(data: {file: File; dataUrl: string}) {
    this.thumbnailImage = [];
    this.thumbnailImage.push(data.file);
    this.fileName = data.file.name;
    this.thumbName = data.file.name;
    this.thumbnailUrl = data.dataUrl;
  }

  /*insertText( data: { file: File; dataUrl: string } ){

  }*/

  getReplacer(content: string) {
    let contentReplace = content.replaceAll('img src', 'img style="max-width:720px;" src');
    if (this.dataUrlList.length) {
      this.dataUrlList.forEach((dataUrl, dataIdx) => {
        contentReplace = contentReplace.replace(dataUrl, `#ReplaceImage.${dataIdx}`);
      });
    }
    return contentReplace;
  }

  getIframeSize(content: string){
    const iframeArray = content.split('</iframe>');
    let newContent = '';
    iframeArray.forEach((iframe, idx)=>{
      newContent += iframe;
      if(idx < iframeArray.length-1){
        newContent += '</iframe></div>';
      }
    })
    const iframeDivArray = newContent.split('<iframe ');
    let returnContent = '';
    iframeDivArray.forEach((iframe, idx)=>{
      returnContent += iframe;
      if(idx < iframeArray.length-1) {
        returnContent += '<div style="width:100%;position:relative;padding-bottom:56.25%;"><iframe style="width:100%;height:100%;position:absolute;" ';
      }
    });
    return returnContent;
  }


  handleImageRemove(dataUrl: string) {
    const idx = this.dataUrlList.indexOf(dataUrl);
    if (idx >= 0) {
      this.dataUrlList.splice(idx, 1);
      this.uploadImages.splice(idx, 1);
    }
  }

  async handleImageAdded(file: File, Editor, cursorLocation, resetUploader) {
    if (!file) return;
    const isImage = this.isImage(file);
    if (isImage) {
      const dataUrl = await this.getDataUrl(file);
      const sendData = {
        file,
        dataUrl,
      };
      this.imageAppend(sendData, Editor, cursorLocation);
    }
  }

  private imageAppend(data: { file: File; dataUrl: string }, Editor?, cursorLocation?) {
    this.uploadImages.push(data.file);
    this.fileName = data.file.name;
    this.dataUrlList.push(data.dataUrl);
    if(Editor) Editor.insertEmbed(cursorLocation, 'image', data.dataUrl);
    else this.blog.CONTENTS += `<img style="max-width: 720px" src="${data.dataUrl}">`;
  }

  isImage(file: File): boolean {
    const { type } = file;
    const typeList = ['jpg', 'jpeg', 'png'];
    const [image, imageType] = type.split('/');
    if (image !== 'image' || !typeList.includes(imageType)) {
      this.$common.makeToast(ToastMessage.IMAGE_TYPE, ToastVariant.DANGER, this.$bvToast);
      return false;
    } else {
      return true;
    }
  }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + '');
    });
  }
}
