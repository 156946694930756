
import { Component, Vue } from 'vue-property-decorator';
import ChangeNickname from '@/views/user/component/ChangeNickname.vue';
import {UserInfo} from "@/interface/user/user";
import {USER_RATING, USER_TYPE} from "@/enum/user";

interface SelectBox {
  key: string | boolean,
  text: string
}

interface UpdateState {
  name: boolean,
  type: boolean,
  phone: boolean,
  rating: boolean,
  use: boolean,
  userType: boolean
}

@Component({
  components: { ChangeNickname },
})
export default class ProfileInfo extends Vue {

  originName = '';
  selectRegistState: SelectBox[] = [
    { key: true, text: '정상' },
    { key: false, text: '탈퇴' }
  ];

  selectUserRating: SelectBox[] = [
    { key: USER_RATING.BRONZE, text: '신규가입 회원' },
    { key: USER_RATING.SILVER, text: '일반회원' },
    { key: USER_RATING.GOLD, text: '우수회원' },
    { key: USER_RATING.VIP, text: '최우수회원' },
    { key: USER_RATING.PM, text: 'PM' },
  ];

  selectUserType: SelectBox[] = [
    { key: USER_TYPE.NORMAL, text: '개인' },
    { key: USER_TYPE.UNIVERSITY, text: '학교' },
    { key: USER_TYPE.COMPANY, text: '기업' },
  ]

  userInfo: UserInfo = {
    NAME: '',
    MARKETING_AUTH: false,
    PHONE: '',
    USER_TYPE: '',
    _id: '',
    DEPART_ID: '',
    EMAIL_AUTH: false,
    EMAIL_AUTH_DT: new Date(),
    FAVORITE: [],
    LAST_LOGIN_DT: new Date(),
    LAST_LOGIN_IP: '',
    NICKNAME: '',
    OBJECT_ID: '',
    PROJECT: [],
    USE: false,
    USER_RATING: '',
    REGIST_DT: new Date(),
    ENTERPRISE: '',
  };

  pending = true;

  updateState: UpdateState = {
    name: true,
    type: true,
    phone: true,
    rating: true,
    use: true,
    userType: true
  }

  async created() {
    await this.load();
  }

  async load() {
    try {
      const id = this.$route.params.id;
      const { data } = await this.axios.get(`/admin/user/${id}`);
      this.userInfo = data;
      this.originName = data.NAME
      this.pending = false;
    } catch (e) {
      console.log(e);
    }
  }

  stateReset() {
    this.updateState.name = true;
    this.updateState.type = true;
    this.updateState.phone = true;
    this.updateState.rating = true;
    this.updateState.use = true;
    this.updateState.userType = true;
  }

  async updateUserInfo() {
    try {
      this.pending = true;
      const {data} = await this.axios.patch('/admin/user/profile', this.userInfo);
      if (data) {
        alert('변경되었습니다.');
        this.stateReset();
        location.reload();
        // await this.load();
      }
      this.pending = false;
    } catch (e) {
      console.log(e);
    }
  }

  changeUpdateState(type: string): void {
    switch (type) {
      case 'NAME':
        this.updateState.name = !this.updateState.name;
        break;
      case 'TYPE':
        this.updateState.type = !this.updateState.type;
        break;
      case 'PHONE':
        this.updateState.phone = !this.updateState.phone;
        break;
      case 'RATING':
        this.updateState.rating= !this.updateState.rating;
        break;
      case 'USE':
        this.updateState.use = !this.updateState.use;
        break;
      case 'USER_TYPE':
        this.updateState.userType = !this.updateState.userType;
        break;
      default:
        break;
    }
  }
}
