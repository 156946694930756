import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import VueAxios from "vue-axios";
import {ins} from "@/utils/axios";
import {PerfectScrollbar} from "vue2-perfect-scrollbar";
import Vuex from "vuex";
import * as VueMoment from "vue-moment";
import VueToast from 'vue-toast-notification';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CommonClass from "@/utils/CommonClass";
import vClickOutside from 'v-click-outside';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-toast-notification/dist/theme-sugar.css';
// 디자인 스타일 가이드 CSS
import '@/assets/css/index.css';
import '@/assets/css/indes.scss';
import '@/assets/css/sass/bootstrapCustom.scss';


const Font = () => import('@/components/common/typography/Typography.vue');
const Button = () => import('@/components/common/Button.vue');


Vue.config.productionTip = false
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.use(VueAxios, ins);
Vue.use(CommonClass);
Vue.use(Vuex);
Vue.use(VueMoment);
Vue.use(vClickOutside);
Vue.use(VueToast,{ position : 'top-right' });
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('UniFont', Font);
Vue.component('UniButton', Button);

library.add(fas);
library.add(far);

const app = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

export default app;
