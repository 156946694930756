
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Pod, BodyItem } from '@/interface/admin/k8s/pod';
import { DeploymentOne, BodyMetadata } from '@/interface/admin/k8s/deployment.one';
import PodComponent from './compoment/pod.vue';
@Component({
  components: {
    PodComponent,
  },
})
export default class K8sPod extends Vue {
  private loading = false;
  private items: BodyItem[] = [];
  private metaData: BodyMetadata | null = null;

  get namespace () {
    return this.$route.params.namespace;
  }

  get deployment () {
    return this.$route.params.deployment;
  }

  get pod () {
    return this.$route.params.pod;
  }

  private async created() {
    this.load();
  }

  private async deploymentInfo() {
    const url = `/admin/k8s/deployment/${ this.namespace }/${ this.deployment }`;
    const { data: { body: { metadata } } } =await this.axios.get<DeploymentOne>(url);
    return metadata;
  }

  private async podsList() {
    const url = `/admin/k8s/pod/${this.namespace}/${ this.deployment }`;
    const { data: { body: { items } } } = await this.axios.get<Pod>(url);
    return items;
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.deploymentInfo();
      this.items = [];
      this.items = await this.podsList();
    } finally {
      this.loading = false;
    }
  }

}

