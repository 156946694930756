

import {Component, Prop, Vue} from "vue-property-decorator";
import {ToastMessage, ToastVariant} from "@/utils/ToastEnum";

interface SamplingList {
  Label: string;
  Quota: number;
  Current: number;
  Remain: number;
  Progress: number;
  Feasible: number;
}

@Component({})
export default class Sampling extends Vue{
  @Prop() loading: boolean;
  @Prop() snum: number;
  @Prop() inProgress: boolean;
  @Prop() isPanel: boolean;
  samplingList: SamplingList[] = [];


  mounted(){
    this.load();
  }

  async load(){
    const { data } = await this.axios.get<SamplingList[]>(`/admin/project/quota/table/${this.snum}`);
    this.samplingList = data;
  }

  async sampling(quotaIdx: number) {
    const count = prompt('발송량');

    if (!count) return this.$common.makeToast('값을 입력해 주세요', ToastVariant.DANGER, this.$bvToast);

    if (count.replace(/\d/g, '').length) {
      return this.$common.makeToast('숫자만 입력 가능합니다.', ToastVariant.DANGER, this.$bvToast);
    }

    const max = this.samplingList[quotaIdx].Remain * 3;
    if (Number(count) > max) {
      return this.$common.makeToast(`최대 ${max}건 발송 가능합니다.`, ToastVariant.DANGER, this.$bvToast);
    }

    try {
      const { data } = await this.axios.post(
        `/admin/project/sampling/${this.snum}`,
        {
          quotaIdx,
          count,
        }
      );

      const { result } = data;

      if (result) {
        return this.$common.makeToast(`발송 완료`, ToastVariant.SUCCESS, this.$bvToast);
      }
    } catch (e) {
      console.log(e)
    }

  }
}

