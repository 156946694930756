
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Ingress, Item } from '@/interface/admin/k8s/ingress';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sIngress extends Vue {
  private loading = false;
  private items: Item[] = [];

  created() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      this.items = await this.getIngresses();
      console.log(this.items);
    } finally {
      this.loading = false;
    }
  }

  async getIngresses() {
    const url = `/admin/k8s/ingress`;
    const { data: { body: { items } } } = await this.axios.get<Ingress>(url);
    return items;
  }

}

