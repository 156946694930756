
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Namespace, Item } from '@/interface/admin/k8s/namespace';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sNameSpace extends Vue {
  private loading = false;
  private showCreateNamespaceModal = false;
  private nameSpaceModel: string | null = null;
  private items: Item[] = [];

  get namespace () {
    return this.$route.params.namespace;
  }

  private async created() {
    this.load();
  }

  private async load() {
    try {
      this.loading = true;
      const url = `/admin/k8s/namespace`;
      const { data: { body: { items } } } = await this.axios.get<Namespace>(url);
      this.items = items;
    } finally {
      this.loading = false;
    }
  }

  private async createNameSpace() {
    try {
      this.loading = true;
      const url = `/admin/k8s/namespace`;
      const { data } = await this.axios.post(url, { namespace: this.nameSpaceModel });
      console.log(data);
      await this.load();
    } finally {
      this.loading = false;
    }
  }

  private async deleteNamespace(namespace: string) {
    try {
      this.loading = true;
      const url = `/admin/k8s/namespace`;
      const { data: { body: { items } } } = await this.axios.delete<Namespace>(url, { data: { namespace } });
      this.items = items;
    } finally {
      this.loading = false;
    }
  }

}

