
import { Component, Vue } from "vue-property-decorator";
import {IUserListInfo} from "@/interface/user/user";
import { Dictionary } from 'vue-router/types/router';
import SearchIcon from "@/components/icons/common/SearchIcon.vue";

@Component({
  components: {
    SearchIcon,
  }
})
export default class UserList extends Vue{
  page = 1;
  totalRow = 0;
  numberOfPages = 1;
  limitPage = 10;
  search = '';
  searchType = 'NAME';
  loading = false;
  searchButtonHover = false;
  userList: IUserListInfo[] = [];

  searchOptions=[
    { value: 'NAME', text: '이름' },
    { value: 'ID', text: '회원ID' },
    { value: 'TYPE', text: '유형' },
    { value: 'ENTERPRISE', text: '소속' },
  ]
  userTypes =[
    { value: 'NORMAL', text: '개인' },
    { value: 'UNIVERSITY', text: '학교' },
    { value: 'COMPANY', text: '기업' },
  ]

  limitOptions = [
    { value: 10, text: '10개씩 보기' },
    { value: 20, text: '20개씩 보기' },
    { value: 30, text: '30개씩 보기' },
    { value: 40, text: '40개씩 보기' },
    { value: 50, text: '50개씩 보기' },
  ];

  redirectUserDetail(userId: string) {
    this.$router.push({ path: `/admin/user/${userId}`, query: this.$route.query })
  }

  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  changeFilter(filterValue: { [key: string]: string | number }) {
    const query = this.makeQuery({
      page: 1,
      ...filterValue
    })
    this.$router.push(query);
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    })
  }

  isSelected (userId: string) {
    const { id } = this.$route.params;
    return id === userId;
  }

  async created(){
    this.init();
    await this.load();
  }

  init() {
    const { page, search, searchType, limitPage } = this.$route.query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
    if (limitPage) this.limitPage = +limitPage;
  }

  async load() {
    this.loading = true;
    try {
      const { data } = await this.axios.get('/admin/user/list', {
        params: {
          pageNum: this.page,
          searchType: this.searchType,
          search: this.search,
          perPage: this.limitPage,
        }
      });
      const { result, list, numberOfPages } = data;
      if (result) {
        this.userList = list;
        this.numberOfPages = numberOfPages;
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  numberComma(number){
    return new Intl.NumberFormat().format(number);
  }

  async xlsxDownload(){
    this.loading = true;
    try{
      const paramData = {
        pageNum: this.page,
        searchType: this.searchType,
        search: this.search,
        perPage: this.limitPage,
      }
      const data = await this.axios({
        url: '/admin/user/xlsx',
        method: 'GET',
        params: paramData,
        responseType: 'blob',
      }).then((response)=>{
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', '회원리스트.xlsx')
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
      })
    }catch (e) {
      console.log(e)
    }finally {
      this.loading = false;
    }
  }

  convertUserType(value: string, type: string) {
    switch (value) {
      case 'type':
        return this.$common.covertUserType(type);
      case 'rating':
        return this.$common.convertUserRating(type);
    }
  }
}
