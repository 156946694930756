
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class ChangeNickname extends Vue {
  @Prop() userNickname!: string;
  @Prop() userId!: string;
  @Ref() readonly refNickname!: HTMLElement;
  private nickname = '';

  @Emit('close')
  close(bool = false) {
    return bool;
  }

  mounted() {
    // 바로 줬을 경우 focus가 가질 않아 setTimeout 사용
    setTimeout(() => {
      this.$nextTick(() => this.refNickname.focus());
    }, 300);
  }

  private async changeNickname() {
    const sendData = {
      userId: this.userId,
      newNickname: this.nickname,
    };
    // 동일한 이름일 경우 변경 불가능
    if (this.userNickname === this.nickname) {
      Vue.$toast.error('동일한 이름으로 변경하실 수 없습니다.');
      this.nickname = '';
      return;
    }

    const { data } = await this.axios.patch('/user/change/nickname', sendData);
    const { result } = data;
    if (result) {
      setTimeout(() => {
        Vue.$toast.success('변경되었습니다.');
      }, 100);
      this.close(true);
    }
  }
}
