
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LogoutModal extends Vue {
  async logout() {
    this.$bvModal.hide('logout-modal');
    await this.$store.dispatch('logOut');
    await this.$router.push('/login');
  }

  cancel() {
    this.$bvModal.hide('logout-modal');
  }
}
