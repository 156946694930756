
import { Component, Vue } from 'vue-property-decorator';
import '@/assets/css/component/layout/user-setting.css';
import moment from 'moment';
import { Dictionary } from 'vue-router/types/router';

@Component({ components: {} })
export default class ProjectInfo extends Vue {
  paymentHistory: {
    projectId: number;
    projectTitle: string;
    price: string;
    purchasedDate: string;
    receiptUrl: string;
    status: number;
    OPEN_START_DT: string;
    OPEN_END_DT: string;
  }[] = [];
  page = 1;
  totalPage = 1;
  loading = true;

  created() {
    this.init();
    this.getPaymentLists();
  }

  private async init() {
    const { payPage } = this.$route.query as Dictionary<string>;
    if (payPage) this.page = +payPage;
  }

  private async getPaymentLists() {
    const { data: result } = await this.axios.get(`/admin/user/payment/lists/${this.$route.params.id}/${this.page}`);
    const { totalPage, list } = result;
    this.totalPage = totalPage;
    this.paymentHistory = list.map((value) => {
      const { status, data, projectId, OPEN_START_DT, OPEN_END_DT } = value;
      const { price, order_name, purchased_at, receipt_url } = data;
      // const convertPrice = (price + ' 원').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      const convertPrice = (price + ' 원').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const dateFormat = moment(purchased_at).format('YYYY-MM-DD hh:mm:ss');
      return {
        projectId: projectId,
        projectTitle: order_name,
        price: convertPrice,
        purchasedDate: dateFormat,
        receiptUrl: receipt_url,
        status,
        OPEN_START_DT,
        OPEN_END_DT
      };
    });
    this.loading = false;
  }

  private showReceipt(url: string): void {
    window.open(url, '', 'height=955, width= 700, top=150, left=150, toolbar=no, resizeable=no');
  }

  // 페이징 처리
  linkGen(page: number) {
    const { query } = this.$route;
    delete query.payPage;
    const q = Object.entries(query)
      .map((entry) => entry.join('='))
      .join('&');
    return `${this.$route.path}?${q}&payPage=${page}`;
  }

  // 결제 상태 CSS
  getPayStatusClass(status: number): string {
    if (status === 1) return 'completePaymentLabel';
    if (status === 20) return 'cancelCompletePaymentLabel';
    return '';
  }
  // 결제 상태 라벨
  payMessage(status: number): string {
    let message = '';
    if (status === 1) message = '결제 완료';
    if (status === 20) message = '결제 취소';
    return message;
  }

  get rows() {
    return this.paymentHistory.length;
  }

  get userId() {
    return this.$route.params.id;
  }
}
