import axios from 'axios';
import store from '@/store/index';
import router from '@/router/index';
import Vue from 'vue';

const baseURL = '/api';
const instance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 20000,
});

instance.interceptors.request.use((config) => {
    if (store) {
        const { token } = store.getters;
        if (token && config.headers) config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
    /*const { token } = store.getters;
      console.log('store.getters: ', store.getters)
      console.log('token: ', token)
      // const token = Vuex.getters.getAccessToken;
      if (token) config.headers['Authorization'] = `Bearer ${token}`;
      return config;*/
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;
        const { data, status } = response;
        const { message } = data;
        if (status === 401) {
            return router.replace({ path: '/' }).catch(() => ({}));
        } else {
            Vue.$toast.error(message);
            console.log('debug-', data);
        }
        return response;
    }
);

export const ins = instance;
