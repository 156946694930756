
import {Component, Vue} from "vue-property-decorator";
import { Dictionary } from 'vue-router/types/router';


@Component ({
  components: {}
})
export default class QuestionInfo extends Vue {
  page = 1;
  totalRows = 0;
  userId: string = this.$route.params.id;
  loading = false;
  helpList = [];
  search = '';
  searchButtonHover = true;

  created(){
    this.init();
    this.load();
  }
  init() {
    const { questionPage } = this.$route.query as Dictionary<string>;

    if (questionPage) this.page = +questionPage;
  }

  linkGen(questionPage: number) {
    return this.makeQuery({ questionPage });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  private async load(){

    this.loading = true;

    try {
      const { data } = await this.axios.get(`/admin/getQuestionList/${this.userId}/${this.page}`);

      const { list, totalRows } = data;
      this.helpList = list;
      this.totalRows = totalRows;
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  statusColor(status: string): string {
    if (status === '확인중') return 'warning';
    if (status === '답변완료') return 'success';
    return 'info';
  }

  questionView(index: number) {
    const list = this.helpList[index];
    const { _id } = list;

    this.$router.push(`/admin/user/question/${_id}`);
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get host() {
    return this.isProd ? `https://unisurvey.co.kr` : `http://localhost:8080`
  }
}
