
import { Component, Vue } from "vue-property-decorator";
import {
  helpInit,
  IHelp,
  HELP_CATEGORY_OPTION,
  HELP_CATEGORY,
} from "@/types/board";
import { VueEditor } from "vue2-editor";
import ImageUpload from "@/components/question/ImageUpload.vue";
import { ToastMessage, ToastVariant } from "@/utils/ToastEnum";

@Component({
  components: {
    VueEditor,
    ImageUpload,
  },
})
export default class AdminHelpWrite extends Vue {
  loading = false;
  help: IHelp = helpInit(this.$route.query.tabIndex !== "0");
  tag = "";
  tabIndex = this.$route.query.tabIndex;

  customToolbar: any[] = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["image", "code-block"],
  ];
  dataUrlList: string[] = [];
  uploadImages: File[] = [];
  path = location.origin + "/uploads/Question/";
  file = [];
  /*HELP_CATEGORY_OPTION = this.$route.query.tabIndex > 0?
      HELP_CATEGORY_OPTION.filter((r, index) => r.text !== HELP_CATEGORY.NOTICE)
      :HELP_CATEGORY_OPTION.filter((r, index)=> r.text === HELP_CATEGORY.NOTICE);
*/
  HELP_CATEGORY_OPTION =
    this.$route.query.tabIndex !== '0'
      ? HELP_CATEGORY_OPTION.filter(
          (r, index) => r.text !== HELP_CATEGORY.NOTICE
        )
      : HELP_CATEGORY_OPTION.filter(
          (r, index) => r.text === HELP_CATEGORY.NOTICE
        );

  $refs: Vue["$refs"] & {
    formFile: HTMLFormElement;
    tag: HTMLElement;
  };

  async save(): Promise<void> {
    try {
      const { TITLE, CONTENTS, TAG, CATEGORY } = this.help;
      if (TITLE.length < 2) {
        this.$common.makeToast(
          ToastMessage.EMPTY_TITLE,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      } else if (CONTENTS.length < 5) {
        this.$common.makeToast(
          ToastMessage.EMPTY_CONTENT,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      }

      const isFiles = this.dataUrlList.length;
      const replacerContent = isFiles ? this.getReplacer(CONTENTS) : CONTENTS;
      const formData: FormData = new FormData();

      this.uploadImages.forEach((file, fileIdx) => {
        formData.append("fileList", file);
      });
      const sendData = {
        title: TITLE,
        category: CATEGORY,
        contents: replacerContent,
        tag: TAG,
      };

      const { data } = await this.axios.post(`/board/help-write`, sendData);
      if (this.dataUrlList.length) {
        const { _id } = data;
        await this.uploadFile(_id, formData);
      }

      const { result } = data;

      if (result) {
        this.$common.makeToast(
          ToastMessage.WRITE_HELP_BOARD,
          ToastVariant.SUCCESS,
          this.$bvToast
        );
        await this.$router.push("/admin/qna");
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadFile(_id: string, formData: FormData) {
    const { data } = await this.axios.post(
      `/board/help-write/${_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  private imageAppend(data: { file: File; dataUrl: string }) {
    this.uploadImages.push(data.file);
    this.dataUrlList.push(data.dataUrl);
    this.help.CONTENTS += `<img src="${data.dataUrl}" style="max-width: 360px">`;
  }

  getReplacer(content: string) {
    if (this.dataUrlList.length) {
      this.dataUrlList.forEach((dataUrl, dataIdx) => {
        content = content.replace(dataUrl, `#ReplaceImage.${dataIdx}`);
      });
    }
    return content;
  }

  addTag(): void {
    if (this.tag == "") {
      this.$common.makeToast(
        ToastMessage.EMPTY_TAG,
        ToastVariant.DANGER,
        this.$bvToast
      );
      this.$refs.tag.focus();
      return;
    } else if (this.tag.indexOf(" ") > -1) {
      this.$common.makeToast(
        ToastMessage.NOT_BLANK_TAG,
        ToastVariant.DANGER,
        this.$bvToast
      );
      this.$refs.tag.focus();
      return;
    } else if (this.help.TAG.indexOf(this.tag) > -1) {
      this.$common.makeToast(
        ToastMessage.SAME_TAG,
        ToastVariant.DANGER,
        this.$bvToast
      );
      this.$refs.tag.focus();
      return;
    }
    this.help.TAG.push(this.tag);
    this.tag = "";
  }

  handleImageRemove(dataUrl: string) {
    const idx = this.dataUrlList.indexOf(dataUrl);
    if (idx >= 0) {
      this.dataUrlList.splice(idx, 1);
      this.uploadImages.splice(idx, 1);
    }
  }

  async handleImageAdded(file: File, Editor, cursorLocation, resetUploader) {
    if (!file) return;
    const isImage = this.isImage(file);
    if (isImage) {
      const dataUrl = await this.getDataUrl(file);
      const sendData = {
        file,
        dataUrl,
      };
      this.imageAppend(sendData);
    }
  }

  isImage(file: File): boolean {
    const { type } = file;
    const typeList = ["jpg", "jpeg", "png"];

    const [image, imageType] = type.split("/");
    if (image !== "image" || !typeList.includes(imageType)) {
      this.$common.makeToast(
        ToastMessage.IMAGE_TYPE,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return false;
    } else {
      return true;
    }
  }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + "");
    });
  }
}
