
import { Component, Ref, Vue } from 'vue-property-decorator';
import { USER } from '@/types/user';
import { IUserLogin } from '@/interface/user/user';
import PasswordIcon from "@/components/icons/login/PasswordIcon.vue";
import IdIcon from "@/components/icons/login/IdIcon.vue";

@Component({
  components: {IdIcon, PasswordIcon}
})
export default class Login extends Vue {
  @Ref() passwordInput: HTMLElement;

  idFocus = false;
  passwordFocus = false;
  idActive = 'id_focus';
  passwordActive = 'password_focus';
  idFocusInput = 'id_focus_input';
  pwFocusInput = 'password_focus_input';

  loginInfo: IUserLogin;
  failCount: number;
  failFlag: string;
  isLoading = false;

  constructor() {
    super();
    this.loginInfo = USER.LoginInit();
    this.failCount = 0;
    this.failFlag = '';
  }

  async login(): Promise<void> {
    if (!this.idValidation()) return;
    if (!this.pwdValidation()) return;

    const sendData = this.createSendData();

    const { data } = await this.axios.post(`/admin/user/login`,sendData);
    const { result } = data;
    if (result) {
      await this.$store.dispatch('login', { data });
      await this.$router.push('/admin/list');
    } else {
      this.loginInfo.userPwd = '';
      this.failFlag = data.failFlag || '';
      if (this.failFlag == 'auth') {
        this.$toast.error(data.message || 'error!');
      }
      this.failCount++;
      this.failCheck();
    }
  }

  idValidation(): boolean {
    const isIdNull = !this.loginInfo.userId;
    if (isIdNull) {
      this.$toast.error('아이디를 입력해 주세요.');
      return false;
    }
    return true;
  }

  pwdValidation(): boolean {
    const isPwNull = !this.loginInfo.userPwd;
    if (isPwNull) {
      this.$toast.error('비밀번호를 입력해 주세요.');
      return false;
    }
    return true;
  }

  failCountInit(): void {
    this.failCount = 0;
  }

  failCheck(): boolean {
    const overTry = this.failCount >= 5;
    if (overTry) {
      setTimeout(this.failCountInit, 10000);
      return false;
    }
    return true;
  }

  createSendData(): IUserLogin {
    const { userId, userPwd } = this.loginInfo;
    return {
      userId,
      userPwd,
    };
  }

  get idState(): boolean | null {
    if (!this.loginInfo.userId) return null;
    return this.loginInfo.userId.length > 0;
  }

  get passwordState(): boolean | null {
    if (!this.loginInfo.userPwd) return null;
    return this.loginInfo.userPwd.length >= 8;
  }

  get failState(): boolean {
    return this.failCount >= 5;
  }

  focusPw() {
    this.passwordInput.focus();
  }
}
