
import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class ProjectViewSkeleton extends Vue{

  loading = false;
  loadingTime = 0;
  maxLoadingTime = 3;



}
