
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Node, Item } from '@/interface/admin/k8s/node';
import { NodeUsage, Item as NodeUsageItem } from '@/interface/admin/k8s/node.usage';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sNodes extends Vue {

  private loading = false;
  private items: Item[] = [];
  private usageItems: NodeUsageItem[] = [];

  private get nf() {
    return new Intl.NumberFormat('en-US', { style: 'unit', unit: 'gigabyte', maximumFractionDigits: 1 })
  }

  async created() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      this.items = await this.getNodes();
      this.usageItems = await this.getNodesUsage();
    } finally {
      this.loading = false;
    }
  }

  async getNodesUsage() {
    const url = `/admin/k8s/nodes/top`;
    const { data: { items } } = await this.axios.get<NodeUsage>(url);
    return items;
  }

  async getNodes() {
    const url = `/admin/k8s/nodes`;
    const { data: { body: { items } } } = await this.axios.get<Node>(url);
    return items;
  }

  private filterUsage(nodeName: string) {
    const find = this.usageItems.find(({ metadata: { name } }) => name === nodeName);
    return find ? {
      cpu: (+find.usage.cpu.replace(/[^0-9]/g, '') / (1024 ** 2)).toFixed(0) + 'm',
      memory: +find.usage.memory.replace(/[^0-9]/g, '') / (1024 ** 2)
    } : {
      cpu: `0m`,
      memory: 0
    };
  }
}

