export const USER_RATING = {
  BRONZE: 'BRONZE',
  SILVER: 'SILVER',
  GOLD: 'GOLD',
  VIP: 'VIP',
  PM: 'PM',
}

export const USER_TYPE = {
  NORMAL: 'NORMAL',
  COMPANY: 'COMPANY',
  UNIVERSITY: 'UNIVERSITY'
}

