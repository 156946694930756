
import {Component, Vue} from "vue-property-decorator";
import ProfileInfo from '@/views/user/component/ProfileInfo.vue';
import PaymentInfo from '@/views/user/component/PaymentInfo.vue';
import QuestionInfo from '@/views/user/QuestionInfo.vue';
import ProjectInfo from "@/views/user/component/ProjectInfo.vue";


@Component({
  components: {
    QuestionInfo,
    ProfileInfo,
    ProjectInfo,
  },
})
export default class UserView extends Vue {
  created() {
    this.$store.dispatch('socketStore/emitAdminCheckUser', this.$route.params.id);
  }

}
