import {USER_RATING, USER_TYPE} from "@/enum/user";

class CommonClass{
    //숫자 콤마
    private commaNumberRegex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;

    convertUserRating(type: string): string {
        switch (type) {
            case USER_RATING.BRONZE:
                return '신규가입 회원'
            case USER_RATING.SILVER:
                return '일반회원'
            case USER_RATING.GOLD:
                return '우수회원'
            case USER_RATING.VIP:
                return '최우수회원'
            default:
                return '일반회원';
        }
    }

    covertUserType(type: string): string {
        switch (type) {
            case USER_TYPE.COMPANY:
                return '기업';
            case USER_TYPE.NORMAL:
                return '개인';
            default:
                return '학교';
        }
    }

    dateFormat(date: Date): string {
        let month: number | string = date.getMonth() + 1;
        let day: number | string = date.getDate();
        let hour: number | string = date.getHours();
        let minute: number | string = date.getMinutes();
        //let second: number| string = date.getSeconds();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        //second = second >= 10 ? second : '0' + second;

        return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute;
    }

    numberFormat(str: number | string): string {
        return String(str).replace(this.commaNumberRegex, ',');
    }


    makeToast(message: string, variant: string, bToast: any): void {
        bToast.toast(message, { variant: variant, noCloseButton: true, autoHideDelay: 3000, bodyClass: 'toast-class' });
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $common: CommonClass;
    }
}

export default {
    install(Vue: any) {
        Vue.prototype.$common = new CommonClass();
    },
};


